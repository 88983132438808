import React from "react";
import TankYouPage from "../components/TankYouPage";
import Layout from "../components/Layout";

const seo = {
  title: "Thank You ",
};

const RequestAQuoteThankYou = () => {
  return (
    <Layout seo={seo} backgroundColorUnderneath="white">
      <TankYouPage />
    </Layout>
  );
};

export default RequestAQuoteThankYou;
